import * as Sentry from "@sentry/angular-ivy";
import { APP_ID, APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { ApiModule, Configuration, EstablishmentDto } from "@api";
import { APP_RELEASE, DEFAULT_LANGUAGE, MAP_LANGUAGE_ENUM_TO_ISO, environment } from "@constants";
import { I18nModule } from "@i18n";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatIconModule } from "@angular/material/icon";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { getAnalytics, provideAnalytics } from "@angular/fire/analytics";
import { StoreModule } from "@ngrx/store";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

export const setApiConfig = (): Configuration => new Configuration({ basePath: environment.baseApi });

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule,
    ApiModule.forRoot(setApiConfig),
    I18nModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatIconModule,

    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: () => (): void => {}, deps: [Sentry.TraceService], multi: true },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService): string => translateService.currentLang
        ? MAP_LANGUAGE_ENUM_TO_ISO[translateService.currentLang.toUpperCase() as EstablishmentDto.EnabledLanguagesEnum]
        : MAP_LANGUAGE_ENUM_TO_ISO[DEFAULT_LANGUAGE],
    },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false, logErrors: true }) },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_ID, useValue: "admin" },
    { provide: APP_RELEASE, useValue: environment.release },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
