export const environment = {
  baseApi: "https://api.ohmymenu.com",
  baseApp: "https://menu.ohmymenu.com/home",
  firebase: {
    projectId: "com-ohmymenu",
    appId: "1:229737233171:web:03f0ca38027a7c543838e6",
    databaseURL: "https://com-ohmymenu.firebaseio.com",
    storageBucket: "com-ohmymenu.appspot.com",
    locationId: "us-central",
    apiKey: "AIzaSyBZM3lyTqD7CJnFeXaGe2E-zaXK3Ahtq0M",
    authDomain: "com-ohmymenu.firebaseapp.com",
    messagingSenderId: "229737233171",
    measurementId: "G-MWE8Z11FE3",
  },
  googleClientId: "229737233171-8v592h6as057k4dqo07vt1257t5upi60.apps.googleusercontent.com",
  name: "production",
  production: true,
  release: "0.3.37",
  stripeApiKey: "undefined",
  stripePublishableKey: "undefined",
};
